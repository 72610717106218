import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@biogroup/utils/cn'

import { Loader } from './loader.tsx'

const buttonVariants = cva(
  cn(
    'inline-flex items-center gap-x-2 justify-center border whitespace-nowrap py-1 rounded-lg text-sm font-semibold transition-colors duration-150 ring-offset-background',
    'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2',
    'disabled:pointer-events-none',
  ),
  {
    variants: {
      variant: {
        primary: cn(
          'bg-primary text-primary-foreground border-transparent',
          'hover:bg-primary-active hover:text-primary-foreground/70',
          'active:text-primary-foreground',
          'disabled:bg-dull disabled:text-dull-foreground',
        ),
        secondary: cn(
          'bg-transparent text-foreground border-faded',
          'hover:bg-muted hover:text-foreground/60',
          'active:bg-faded active:border-faded',
          'disabled:bg-dull disabled:text-dull-foreground',
        ),
        ghost: cn(
          'bg-transparent text-foreground border-transparent',
          'hover:bg-muted hover:border-faded hover:foreground/60',
          'active:bg-muted active:border-muted',
          'disabled:text-dull',
        ),
      },
      size: {
        default: 'px-3 text-md',
        sm: 'px-2 text-xs',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  isLoading?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size,
      variant,
      className,
      children,
      asChild = false,
      isLoading = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      >
        {isLoading ? (
          <div className="flex items-center gap-x-2">
            {children} <Loader />
          </div>
        ) : (
          children
        )}
      </Comp>
    )
  },
)

Button.displayName = 'Button'

export { Button, buttonVariants }
