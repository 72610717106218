import { type Config } from 'tailwindcss'

export const extendedTheme = {
  colors: {
    ring: {
      DEFAULT: 'var(--ring)',
    },
    background: 'var(--background)',
    foreground: 'var(--foreground)',
    muted: {
      DEFAULT: 'var(--muted)',
      foreground: 'var(--muted-foreground)',
    },
    dull: {
      DEFAULT: 'var(--dull)',
      foreground: 'var(--dull-foreground)',
    },
    faded: 'var(--faded)',
    primary: {
      DEFAULT: 'var(--primary)',
      foreground: 'var(--primary-foreground)',
      active: 'var(--primary-active)',
    },
    secondary: {
      DEFAULT: 'var(--secondary)',
      foreground: 'var(--secondary-foreground)',
      active: 'var(--secondary-active)',
    },
    feedback: {
      info: 'var(--info)',
      success: 'var(--success)',
      warning: 'var(--warning)',
      error: 'var(--error)',
    },
    neutral: {
      0: 'var(--neutral-0)',
      50: 'var(--neutral-50)',
      100: 'var(--neutral-100)',
      200: 'var(--neutral-200)',
      300: 'var(--neutral-300)',
      400: 'var(--neutral-400)',
      500: 'var(--neutral-500)',
      600: 'var(--neutral-600)',
      700: 'var(--neutral-700)',
      800: 'var(--neutral-800)',
      900: 'var(--neutral-900)',
      1000: 'var(--neutral-1000)',
    },
    alpha: {
      0: 'var(--alpha-0)',
      50: 'var(--alpha-50)',
      100: 'var(--alpha-100)',
      200: 'var(--alpha-200)',
      300: 'var(--alpha-300)',
      400: 'var(--alpha-400)',
      500: 'var(--alpha-500)',
      600: 'var(--alpha-600)',
      700: 'var(--alpha-700)',
      800: 'var(--alpha-800)',
      900: 'var(--alpha-900)',
      1000: 'var(--alpha-1000)',
    },
    bravo: {
      0: 'var(--bravo-0)',
      50: 'var(--bravo-50)',
      100: 'var(--bravo-100)',
      200: 'var(--bravo-200)',
      300: 'var(--bravo-300)',
      400: 'var(--bravo-400)',
      500: 'var(--bravo-500)',
      600: 'var(--bravo-600)',
      700: 'var(--bravo-700)',
      800: 'var(--bravo-800)',
      900: 'var(--bravo-900)',
      1000: 'var(--bravo-1000)',
    },
    error: {
      0: 'var(--error-0)',
      50: 'var(--error-50)',
      100: 'var(--error-100)',
      200: 'var(--error-200)',
      300: 'var(--error-300)',
      400: 'var(--error-400)',
      500: 'var(--error-500)',
      600: 'var(--error-600)',
      700: 'var(--error-700)',
      800: 'var(--error-800)',
      900: 'var(--error-900)',
      1000: 'var(--error-1000)',
    },
    warning: {
      0: 'var(--warning-0)',
      50: 'var(--warning-50)',
      100: 'var(--warning-100)',
      200: 'var(--warning-200)',
      300: 'var(--warning-300)',
      400: 'var(--warning-400)',
      500: 'var(--warning-500)',
      600: 'var(--warning-600)',
      700: 'var(--warning-700)',
      800: 'var(--warning-800)',
      900: 'var(--warning-900)',
      1000: 'var(--warning-1000)',
    },
    success: {
      0: 'var(--success-0)',
      50: 'var(--success-50)',
      100: 'var(--success-100)',
      200: 'var(--success-200)',
      300: 'var(--success-300)',
      400: 'var(--success-400)',
      500: 'var(--success-500)',
      600: 'var(--success-600)',
      700: 'var(--success-700)',
      800: 'var(--success-800)',
      900: 'var(--success-900)',
      1000: 'var(--success-1000)',
    },
  },
  borderColor: {
    DEFAULT: 'var(--border)',
  },
  borderRadius: {
    '3xl': 'calc(var(--radius) * 3)', // 24px
    '2xl': 'calc(var(--radius) * 2)', // 16px
    xl: 'calc(var(--radius) + 4px)', // 10px
    lg: 'var(--radius)', // 8px
    md: 'calc(var(--radius) - 2px)', // 6px
    sm: 'calc(var(--radius) - 4px)', // 4px
  },
  fontSize: {
    // 1rem = 16px
    /** 80px size / 84px high / bold */
    mega: [
      '5rem',
      { lineHeight: '5.25rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 64px size / 72px high / bold */
    h1: [
      '4rem',
      { lineHeight: '4.5rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 48px size / 56px high / bold */
    h2: [
      '3rem',
      { lineHeight: '3.5rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 32px size / 40px high / bold */
    h3: [
      '2rem',
      { lineHeight: '2.5rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 20px size / 24px high / bold */
    h4: [
      '1.25rem',
      { lineHeight: '1.5rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 18px size / 24px high / bold */
    h5: [
      '1.125rem',
      { lineHeight: '1.5rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],
    /** 16px size / 20px high / bold */
    h6: [
      '1rem',
      { lineHeight: '1.25rem', fontWeight: '700', letterSpacing: '0.04em' },
    ],

    /** 20px size / 32px high / normal */
    lg: ['1.25rem', { lineHeight: '2rem', letterSpacing: '-0.01em' }],
    /** 16px size / 28px high / normal */
    md: ['1rem', { lineHeight: '1.75rem', letterSpacing: '-0.01em' }],
    /** 14px size / 24px high / normal */
    sm: ['0.875rem', { lineHeight: '1.5rem', letterSpacing: '-0.01em' }],
    /** 12px size / 20px high / normal */
    xs: ['0.75rem', { lineHeight: '1.25rem', letterSpacing: '-0.01em' }],
  },
  fontFamily: {
    sans: 'var(--font-sans)',
    headers: 'var(--font-headers)',
  },
  screens: {
    container: '1280px',
  },
} satisfies Config['theme']
